import jwtDecode from "jwt-decode";
import stateManager from './stateManager'

class UserManager {
    public getUserInfo() {
        const item = stateManager.get("userInfo", undefined, function (value) {
            if (typeof value.roles === "string" && typeof value.codes === "string") {
                value.roles = value.roles ? value.roles.split(",") : []
                value.codes = value.codes ? value.codes.split(",") : []
            }
            return value
        })
        if (item && item.exp < new Date().getTime() / 1000) {
            return null
        }
        return item
    }

    public setImpersonateUserInfo(data: any) {
        stateManager.set("impersonateUserInfo", data)
    }

    public getImpersonateUserInfo() {
        return stateManager.get("impersonateUserInfo")
    }

    public deleteImpersonateUserInfo() {
        stateManager.delete("impersonateUserInfo")
    }

    public setAccessToken(accessToken: string) {
        stateManager.set("accessToken", accessToken)
        stateManager.set("userInfo", jwtDecode(accessToken))
    }

    public getAccessToken() {
        return stateManager.get("accessToken")
    }

    public deleteAccessToken() {
        stateManager.delete("accessToken")
        stateManager.delete("userInfo")
        stateManager.delete("impersonateUserInfo")
    }

    public checkRole(roles, ignoreT = false, ignoreKtt = false) {
        if (!(roles instanceof Array)) {
            roles = [roles]
        }
        for (const roleItem of this.getUserInfo().roles) {
            if (!ignoreT && roleItem === "t") {
                return true
            }
            for (const role of roles) {
                if (role === roleItem || (!ignoreKtt && role === "kt" && roleItem === "ktt")) {
                    return true
                }
            }
        }
        return false
    }
}

export default new UserManager()