import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import userManager from "@/services/userManager"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '',
        redirect: "/app/index/business"
    },
    {
        path: '/login',
        component: () => import('../views/Login.vue'),
        beforeEnter(from, to, next) {
            const userInfo = userManager.getUserInfo()
            if (userInfo) {
                next("/app/index/business")
            } else {
                next()
            }
        },
    },
    {
        path: '/app',
        component: () => import('../views/app/Layout.vue'),
        beforeEnter(from, to, next) {
            const userInfo = userManager.getUserInfo()
            if (!userInfo) {
                next("/login")
            } else {
                next()
            }
        },
        children: [
            {
                path: "",
                redirect: "index"
            },
            {
                path: "index",
                component: () => import('../views/app/Index.vue'),
                children: [
                    {
                        name: "notification",
                        path: "notification",
                        component: () => import('../views/app/notification/Index.vue'),
                    },
                    {
                        name: "notification.detail",
                        path: "notification/:id/detail",
                        component: () => import('../views/app/notification/Detail.vue'),
                    },
                    {
                        name: "process",
                        path: "process",
                        component: () => import('../views/app/process/Index.vue'),
                    },
                    {
                        name: "process.detail",
                        path: "process/:id/:title",
                        component: () => import('../views/app/process/Detail.vue'),
                    },
                    {
                        name: "guider",
                        path: "guider",
                        component: () => import('../views/app/guider/Index.vue'),
                    },
                    {
                        name: "guider.detail",
                        path: "guider/:id/:title",
                        component: () => import('../views/app/process/Detail.vue'),
                    },
                    {
                        name: "diagram",
                        path: "diagram",
                        component: () => import('../views/app/diagram/Index.vue'),
                    },
                    {
                        name: "diagram.detail",
                        path: "diagram/:id/:title",
                        component: () => import('../views/app/diagram/Detail.vue'),
                    },
                    {
                        name: "business",
                        path: "business",
                        component: () => import('../views/app/business/Index.vue'),
                    },
                ]
            },
            {
                path: "users",
                component: () => import('../views/app/users/Index.vue'),
            },
            {
                path: "users/:id/staffs",
                component: () => import('../views/app/users/Staffs.vue'),
            },
            {
                path: "users/:id/tpkds",
                component: () => import('../views/app/users/Tpkds.vue'),
            },
            {
                path: "customers",
                component: () => import('../views/app/Customers.vue'),
            },
            {
                path: "pricing_items",
                component: () => import('../views/app/PricingItems.vue'),
            },
            {
                path: "virtual_packages",
                component: () => import('../views/app/VirtualPackages.vue'),
            },
            {
                path: "packages",
                component: () => import('../views/app/packages/Index.vue'),
            },
            {
                path: "packages_load_pending",
                component: () => import('../views/app/packages/LoadPending.vue'),
            },
            {
                path: "packages_money",
                component: () => import('../views/app/packages/Money.vue'),
            },
            {
                path: "packages_register",
                component: () => import('../views/app/packages/Register.vue'),
            },
            {
                path: "packages_vn",
                component: () => import('../views/app/packages/Vn.vue'),
            },
            {
                path: "trucks",
                component: () => import('../views/app/trucks/Index.vue'),
            },
            {
                path: "trucks/:id",
                component: () => import('../views/app/trucks/Detail.vue'),
            },
            {
                path: "trucks/:id/check",
                component: () => import('../views/app/trucks/DetailCheck.vue'),
            },
            {
                path: "trucks/:id/weight",
                component: () => import('../views/app/trucks/DetailWeight.vue'),
            },
            {
                path: "trucks_export",
                component: () => import('../views/app/trucks/Export.vue'),
            },
            {
                path: "trucks_export/:id",
                component: () => import('../views/app/trucks/ExportDetail.vue'),
            },
            {
                path: "liquidation_slips",
                component: () => import('../views/app/liquidation_slips/Index.vue'),
            },
            {
                path: "liquidation_slips/:id",
                component: () => import('../views/app/liquidation_slips/Detail.vue'),
            },
            {
                path: "discussions",
                component: () => import('../views/app/discussions/Index.vue'),
            },
            {
                path: "discussions/:id",
                component: () => import('../views/app/discussions/Detail.vue'),
            },
            {
                path: "conversations",
                component: () => import('../views/app/conversations/Index.vue'),
            },
            {
                path: "conversations/:id",
                component: () => import('../views/app/conversations/Detail.vue'),
            },
            {
                path: "reports",
                component: () => import('../views/app/reports/Layout.vue'),
                children: [
                    {
                        path: "",
                        redirect: "index"
                    },
                    {
                        path: "index",
                        component: () => import('../views/app/reports/Index.vue'),
                    },
                    {
                        path: "total",
                        component: () => import('../views/app/reports/Total.vue'),
                    },
                    {
                        path: "customers",
                        component: () => import('../views/app/reports/Customers.vue'),
                    },
                    {
                        path: "customers/:id",
                        component: () => import('../views/app/reports/CustomerDetail.vue'),
                    },
                    {
                        path: "new_customers",
                        component: () => import('../views/app/reports/NewCustomers.vue'),
                    },
                    {
                        path: "packages",
                        component: () => import('../views/app/reports/Packages.vue'),
                    },
                    {
                        path: "trucks",
                        component: () => import('../views/app/reports/Trucks.vue'),
                    },
                    {
                        path: "truck_xnk",
                        component: () => import('../views/app/reports/TruckXnk.vue'),
                    },
                    {
                        path: "user_kt",
                        component: () => import('../views/app/reports/UserKt.vue'),
                    },
                    {
                        path: "user_kd",
                        component: () => import('../views/app/reports/UserKd.vue'),
                    },
                    {
                        path: "user_tpkd",
                        component: () => import('../views/app/reports/UserTpkd.vue'),
                    },
                    {
                        path: "user_htkd",
                        component: () => import('../views/app/reports/UserHtkd.vue'),
                    },
                    {
                        path: "user_gd",
                        component: () => import('../views/app/reports/UserGd.vue'),
                    },
                ]
            },
            {
                path: "bills",
                component: () => import('../views/app/bill/Index.vue')
            },
            {
                path: "suggestions",
                component: () => import('../views/app/suggestions/Index.vue')
            },
            {
                path: "policies",
                component: () => import('../views/app/policies/Index.vue')
            }
        ],
    },
    {
        path: "/ext/liquidation_slips/:id",
        component: () => import('../views/app/liquidation_slips/DetailExt.vue'),
    },
]

const router = new VueRouter({
    base: "/ui/",
    routes
})

export default router
