export default {
  "LOGOUT": "Đăng xuất",
  "USERS": "Tài khoản",
  "CUSTOMERS": "Khách hàng",
  "VIRTUAL_PACKAGES": "Nhờ thanh toán",
  "PACKAGES_ALL": "Tất cả hàng",
  "PACKAGES_LOAD_PENDING": "Chờ xếp xe",
  "PACKAGES_MONEY": "Tiền hàng",
  "PACKAGES_REGISTER": "Khai báo",
  "PACKAGES_VN": "Hàng đã về",
  "TRUCKS_LOADED": "Đã xếp xe",
  "TRUCKS_EXPORT": "Kê khai HQ",
  "LOGIN": "Đăng nhập",
  "LOGIN_NAME": "Tên đăng nhập",
  "PASSWORD": "Mật khẩu",
  "REQUIRED": "bắt buộc",
  "CREATE_USER": "Tạo tài khoản",
  "NAME": "Tên",
  "ROLES": "Quyền",
  "CODES": "Mã nhân viên",
  "CREATED_AT": "Ngày tạo",
  "UPDATED_AT": "Ngày cập nhật",
  "ACTIONS": "Hành động",
  "EDIT": "Chỉnh sửa",
  "DELETE": "Xóa",
  "CANCEL": "Hủy",
  "SAVE": "Lưu",
  "CONFIRM_DELETE": "Bạn có chắc chắn muốn xóa mục này?",
  "OK": "Đồng ý",
  "NO_DATA": "Chưa có dữ liệu",
  "ROLE_K": "Kho",
  "ROLE_XNK": "XNK",
  "ROLE_KD": "Kinh doanh",
  "ROLE_T": "Tổng",
  "ROLE_KT": "Kế toán",
  "ROLE_TD": "Theo dõi",
  "ROLE_GD": "Giao dịch",
  "ROLE_HTKD": "Hỗ trợ kinh doanh",
  "CREATE_PACKAGE": "Nhập dữ liệu",
  "PACKAGE_ID": "Mã đơn hàng",
  "PACKAGE_BATCH_ID": "Mã hàng lô",
  "USER_NAME": "Tài khoản",
  "PACKAGE_COUNT": "Số kiện",
  "WEIGHT": "Cân nặng",
  "VOLUME": "Thể tích",
  "INTERIOR_COST": "Phí nội địa",
  "PACKAGE_METHOD": "Cách đóng hàng",
  "NOTE": "Ghi chú",
  "IMAGE": "Hình ảnh",
  "TRUCK_ID": "Mã xe",
  "PENDING": "Chờ xếp xe",
  "STORE_TIME": "Ngày vào kho",
  "TOTAL_WEIGHT": "Tổng cân nặng",
  "TOTAL_VOLUME": "Tổng thể tích",
  "LOAD": "Xếp xe",
  "EXCEL_EXPORT": "Xuất excel (rút gọn)",
  "EXCEL_ADVANCED_EXPORT": "Xuất excel (chi tiết)",
  "TRUCK_DETAIL": "Xem chi tiết",
  "STATUS": "Trạng thái",
  "LICENSE_PLATE": "Biển số xe",
  "LOAD_TIME": "Ngày xếp",
  "GATE_TIME": "Ngày đến cửa khẩu",
  "VN_TIME": "Ngày về VN",
  "STATUS_DK": "Dự kiến",
  "STATUS_X": "Đã xếp",
  "STATUS_CK": "Cửa khẩu",
  "STATUS_VN": "Về VN",
  "TRUCK": "Xe",
  "SUBMIT_LOAD": "Chốt",
  "ADD_PACKAGE_ID": "Thêm mã đơn hàng",
  "CONFIRM_PACKAGE_CANCEL": "Bạn có muốn hủy xếp xe của đơn hàng này?",
  "PACKAGE_CANCEL": "Hủy xếp xe",
  "SEARCH": "Tìm kiếm",
  "TRUCK_CREATED_TIME": "Ngày báo xếp",
  "NOTIFICATION": "Thông báo",
}
