import axios from "axios"
import configProvider from './configProvider'
import userManager from "./userManager"

class ApiClient {
    private errorHandler: any
    private router: any
    private baseUrl = configProvider.get("BASE_URL");

    public setErrorHandler(errorHandler: any) {
        this.errorHandler = errorHandler
    }

    public setRouter(router: any) {
        this.router = router
    }

    public async authLogin(loginName, password) {
        const response: any = await this.request("POST", "auth/login", {
            "login_name": loginName,
            "password": password,
        }, true)
        return response && response.access_token
    }

    public async authLogout() {
        const response: any = await this.request("POST", "auth/logout")
        return response && true
    }

    public async userGetAll(options: any = {}) {
        return await this.request("GET", "users", this.makeGetAllOptions(options))
    }

    public async userGetById(id) {
        return await this.request("GET", `users/${id}`)
    }

    public async userCreate(data) {
        return await this.request("POST", `users`, data)
    }

    public async userUpdate(data) {
        return await this.request("PUT", `users/${data.id}`, { data })
    }

    public async userDelete(data) {
        return await this.request("DELETE", `users/${data.id}`)
    }

    public async getStaffByUser(id) {
        return await this.request("GET", `users/${id}/staff`)
    }

    public async addStaffToUser(id, staffId, data) {
        return await this.request("POST", `users/${id}/staff/${staffId}`, data)
    }

    public async updateStaffOfUser(id, staffId, data) {
        return await this.request("PUT", `users/${id}/staff/${staffId}`, data)
    }

    public async removeStaffFromUser(id, staffId) {
        return await this.request("DELETE", `users/${id}/staff/${staffId}`)
    }

    public async getStaffByUserAtMonth(id, month) {
        return await this.request("GET", `users/${id}/staff/months/${month}`)
    }

    public async getStaffByUserAtMonthInRange(id, startMonth, endMonth) {
        return await this.request("GET", `users/${id}/staff/months/range/${startMonth}/${endMonth}`)
    }

    public async updateUserMonth(id, month, data) {
        return await this.request("PUT", `users/${id}/months/${month}`, data)
    }

    public async getUserMonth(id, month) {
        return await this.request("GET", `users/${id}/months/${month}`)
    }

    public async getUserMonthInRange(id, startMonth, endMonth) {
        return await this.request("GET", `users/${id}/months/range/${startMonth}/${endMonth}`)
    }

    public async getUserMonths(month) {
        return await this.request("GET", `users/months/${month}`)
    }

    public async getUserMonthsInRange(startMonth, endMonth) {
        return await this.request("GET", `users/months/range/${startMonth}/${endMonth}`)
    }

    public async getTpkdByUser(id) {
        return await this.request("GET", `users/${id}/tpkd`)
    }

    public async addTpkdToUser(id, tpkdId, data) {
        return await this.request("POST", `users/${id}/tpkd/${tpkdId}`, data)
    }

    public async removeTpkdFromUser(id, tpkdId) {
        return await this.request("DELETE", `users/${id}/tpkd/${tpkdId}`)
    }

    public async customerGetAll(options: any = {}) {
        return await this.request("GET", "customers", this.makeGetAllOptions(options))
    }

    public async customerGet(id) {
        return await this.request("GET", `customers/${id}`)
    }

    public async customerCreate(data) {
        return await this.request("POST", `customers`, data)
    }

    public async customerUpdate(data, fields = undefined) {
        return await this.request("PUT", `customers/${data.id}`, { data, fields })
    }

    public async customerDelete(data) {
        return await this.request("DELETE", `customers/${data.id}`)
    }

    public async pricingItemGetAll(options: any = {}) {
        return await this.request("GET", "pricing_items", this.makeGetAllOptions(options))
    }

    public async pricingItemGet(id) {
        return await this.request("GET", `pricing_items/${id}`)
    }

    public async pricingItemCreate(data) {
        return await this.request("POST", `pricing_items`, data)
    }

    public async pricingItemUpdate(data, fields = undefined) {
        return await this.request("PUT", `pricing_items/${data.id}`, { data, fields })
    }

    public async pricingItemDelete(data) {
        return await this.request("DELETE", `pricing_items/${data.id}`)
    }

    public async customerUpdateStampStatus(id, value) {
        return await this.request("PUT", `customers/${id}/stamp_status/${value || 'empty'}`)
    }

    public async virtualPackageGetAll(options: any = {}) {
        return await this.request("GET", "virtual_packages", this.makeGetAllOptions(options))
    }

    public async virtualPackageCreate(data) {
        return await this.request("POST", `virtual_packages`, data)
    }

    public async virtualPackageUpdate(data, fields: any = undefined) {
        return await this.request("PUT", `virtual_packages/${data.id}`, { data, fields })
    }

    public async virtualPackageDelete(data) {
        return await this.request("DELETE", `virtual_packages/${data.id}`)
    }

    public async virtualPackageRestore(data) {
        return await this.request("POST", `virtual_packages/${data.id}/restore`)
    }

    public virtualPackageExport(fields = null, ids = undefined) {
        let fieldStr = ""
        if (fields) {
            fieldStr = fields.join(",")
        }
        let idsStr = ""
        if (ids) {
            idsStr = ids.join(",")
        }
        return this.makeUrl(`virtual_packages/@/export?fields=${fieldStr}&ids=${idsStr}&token=${userManager.getAccessToken()}`)
    }

    public async packageGetAll(options: any = {}) {
        return await this.request("GET", "packages", this.makeGetAllOptions(options))
    }

    public async packageCreate(data) {
        return await this.request("POST", `packages`, data)
    }

    public async packageUpdate(data, fields = undefined) {
        return await this.request("PUT", `packages/${data.id}`, { data, fields })
    }

    public async packageDelete(data) {
        return await this.request("DELETE", `packages/${data.id}`)
    }

    public async packageLoad(items) {
        const ids = []
        for (const item of items) {
            ids.push(item.id)
        }
        return await this.request("POST", `packages/@/load`, { ids })
    }

    public async packageApplyVirtualPackage(id, virtualPackageId) {

        return await this.request("POST", `packages/${id}/apply_virtual_package`, {
            virtual_package_id: virtualPackageId,
        })
    }

    public packageExport(fields = undefined, ids = null, truckId = "") {
        let fieldStr = ""
        if (fields) {
            fieldStr = fields.join(",")
        }
        let idsStr = ""
        if (ids) {
            idsStr = ids.join(",")
        }
        return this.makeUrl(`packages/@/export?fields=${fieldStr}&ids=${idsStr}&truck_id=${truckId}&token=${userManager.getAccessToken()}`)
    }

    public async packageSetTagColor(items, value) {
        const ids = []
        for (const item of items) {
            ids.push(item.id)
        }
        return await this.request("POST", `packages/@/tag_color`, { ids, value })
    }

    public async packageUpdateWeightItems(id, item, items) {
        return await this.request("PUT", `packages/${id}/weight_items`, { item, items })
    }

    public async packageUpdateWeightDoneStatus(id, value) {
        return await this.request("PUT", `packages/${id}/weight_done_status/${value}`)
    }

    public async truckGetAll(options: any = {}) {
        return await this.request("GET", "trucks", this.makeGetAllOptions(options))
    }

    public async truckUpdate(data, fields = undefined) {
        return await this.request("PUT", `trucks/${data.id}`, { data, fields })
    }

    public async truckDelete(data) {
        return await this.request("DELETE", `trucks/${data.id}`)
    }

    public truckExport(data, fields = undefined) {
        let fieldStr = ""
        if (fields) {
            fieldStr = fields.join(",")
        }
        return this.makeUrl(`trucks/${data.id}/export?fields=${fieldStr}&token=${userManager.getAccessToken()}`)
    }

    public truckExportAdvanced(data) {
        return this.makeUrl(`trucks/${data.id}/export_advanced?token=${userManager.getAccessToken()}`)
    }

    public async truckGet(id) {
        return await this.request("GET", `trucks/${id}`)
    }

    public async truckSubmitLoad(id) {
        return await this.request("POST", `trucks/${id}/submit_load`)
    }

    public async truckAddPackage(id, packageId) {
        return await this.request("POST", `trucks/${id}/add_package_id`, {
            "package_id": packageId
        })
    }

    public async truckRemovePackage(id, _package) {
        return await this.request("POST", `trucks/${id}/delete_package_id`, {
            "package_id": _package.package_id
        })
    }

    public async truckImportExportInfo(id, file) {
        return await this.request("POST", `trucks/${id}/import_export_info`, {
            "file": file,
        }, false, true)
    }

    public async truckGetTotalTotalExportTaxedAmount(status, from = 0, to = 0) {
        return await this.request("GET", `trucks/@/total_total_export_taxed_amount/${status}/${from}/${to}`)
    }

    public async liquidationSlipGetAll(options: any = {}) {
        return await this.request("GET", "liquidation_slips", this.makeGetAllOptions(options))
    }

    public async liquidationSlipGet(id) {
        return await this.request("GET", `liquidation_slips/${id}`)
    }

    public async liquidationSlipCreate(data) {
        return await this.request("POST", `liquidation_slips`, data)
    }

    public async liquidationSlipUpdate(data, fields: any = undefined) {
        return await this.request("PUT", `liquidation_slips/${data.id}`, { data, fields })
    }

    public async liquidationSlipDelete(data) {
        return await this.request("DELETE", `liquidation_slips/${data.id}`)
    }

    public async liquidationSlipApprove(data) {
        return await this.request("POST", `liquidation_slips/${data.id}/approve`)
    }

    public async liquidationSlipCancelApprove(data) {
        return await this.request("POST", `liquidation_slips/${data.id}/cancel_approve`)
    }

    public async liquidationSlipCheckSoGds(id, value) {
        return await this.request("GET", `liquidation_slips/${id}/check_so_gds/${value}`)
    }

    public liquidationSlipExport(fields = null, truckId = "") {
        let fieldStr = ""
        if (fields) {
            fieldStr = fields.join(",")
        }
        return this.makeUrl(`liquidation_slips/@/export?fields=${fieldStr}&truck_id=${truckId}&token=${userManager.getAccessToken()}`)
    }

    public async reportGetAll(startTime, endTime) {
        return await this.request("GET", "reports", {
            start: startTime,
            end: endTime,
        })
    }

    public async discussionGetAll(options: any = {}) {
        return await this.request("GET", "discussions", this.makeGetAllOptions(options))
    }

    public async discussionGet(id) {
        return await this.request("GET", `discussions/${id}`)
    }

    public async discussionSwitchRecord(data) {
        return await this.request("PUT", `discussions/${data.id}/switch`, data)
    }

    public async discussionCreate(data) {
        return await this.request("POST", `discussions`, data)
    }

    public async discussionUpdate(data, fields = undefined) {
        return await this.request("PUT", `discussions/${data.id}`, { data, fields })
    }

    public async discussionDelete(data) {
        return await this.request("DELETE", `discussions/${data.id}`)
    }

    public async discussionSetRating(id, value) {
        return await this.request("PUT", `discussions/${id}/rating/${value}`)
    }

    public async discussionGetRatingByTime(startTime, endTime) {
        return await this.request("PUT", `discussions/@/rating/time/${startTime}/${endTime}`)
    }

    public async discussionMessageGetAll(discussionId, options: any = {}) {
        return await this.request("GET", `discussions/${discussionId}/messages`, this.makeGetAllOptions(options))
    }

    public async discussionMessageCreate(discussionId, data) {
        return await this.request("POST", `discussions/${discussionId}/messages`, data)
    }

    public async discussionMessageUpdate(discussionId, data, fields = undefined) {
        return await this.request("PUT", `discussions/${discussionId}/messages/${data.id}`, { data, fields })
    }

    public async discussionMessageDelete(discussionId, data) {
        return await this.request("DELETE", `discussions/${discussionId}/messages/${data.id}`)
    }

    public async notificationGetAll(options: any = {}) {
        return await this.request("GET", "notifications", this.makeGetAllOptions(options))
    }

    public async notificationDelete(data) {
        return await this.request("DELETE", `notifications/${data.id}`)
    }

    public async notificationUpdate(data) {
        return await this.request("PUT", `notifications/${data.id}`, data)
    }

    public async notificationCreate(data) {
        return await this.request("POST", `notifications`, data)
    }

    public async getByIdNotification(id) {
        return await this.request("GET", `notifications/${id}`)
    }

    public async getNotificationByUser(id) {
        return await this.request("GET", `users/${id}/notification`)
    }

    public async changeStatusNotification(data) {
        return await this.request("PUT", `users/${data.user_id}/change_status_notification/${data.notification_id}`)
    }

    public async notificationMessageGetAll(params) {
        return await this.request("GET", `notification_comment`, params)
    }

    public async notificationCreateComment(params) {
        return await this.request("POST", `notification_comment`, params)
    }

    public async processGuiderGetAll(options: any = {}) {
        return await this.request("GET", "process_guiders", this.makeGetAllOptions(options))
    }

    public async processGuiderCreate(data) {
        return await this.request("POST", `process_guiders`, data)
    }

    public async processGuiderUpdate(data) {
        return await this.request("PUT", `process_guiders/${data.id}`, data)
    }

    public async processGuiderDelete(data) {
        return await this.request("DELETE", `process_guiders/${data.id}`)
    }

    public async processGuiderDetail(id) {
        return await this.request("GET", `process_guiders/${id}`)
    }

    public async processGuiderCodeGetAll(options: any = {}) {
        return await this.request("GET", "process_guider_codes", this.makeGetAllOptions(options))
    }

    public async processGuiderCodeCreate(data) {
        return await this.request("POST", `process_guider_codes`, data)
    }

    public async processGuiderCodeDelete(id) {
        return await this.request("DELETE", `process_guider_codes/${id}`)
    }

    public async diagramGetAll(options: any = {}) {
        return await this.request("GET", "diagrams", this.makeGetAllOptions(options))
    }

    public async diagramDetail(id) {
        return await this.request("GET", `diagrams/${id}`)
    }

    public async diagramCreate(data) {
        return await this.request("POST", `diagrams`, data)
    }

    public async diagramUpdate(data) {
        return await this.request("PUT", `diagrams/${data.id}`, data)
    }

    public async diagramDelete(data) {
        return await this.request("DELETE", `diagrams/${data.id}`)
    }

    public async salesGetAll(options: any = {}) {
        return await this.request("GET", `excellent_sales`, this.makeGetAllOptions(options))
    }

    public async salesCreate(data) {
        return await this.request("POST", `excellent_sales`, data)
    }

    public async salesUpdate(data) {
        return await this.request("PUT", `excellent_sales/${data.id}`, data)
    }

    public async billsImportExportInfo(file) {
        return await this.request("POST", `bills_import_excel`, {
            "file": file,
        }, false, true)
    }

    public async billsGetAll(options: any = {}) {
        return await this.request("GET", "bills", this.makeGetAllOptions(options))
    }

    public async billsUpdate(data) {
        return await this.request("POST", `bills/update`, data)
    }

    public async billsNote(data) {
        return await this.request("POST", `bills/note`, data)
    }

    public async billsRequest(data) {
        return await this.request("POST", `bills/request`, data)
    }

    public async billsFiles(data) {
        return await this.request("POST", `bills/files`, data)
    }

    public async billsAgree(data) {
        return await this.request("POST", `bills/agree`, data)
    }

    public async billsSignTheBill(data) {
        return await this.request("POST", `bills/sign_the_bill`, data)
    }

    public billsExport(ids = undefined) {
        let idsStr = ""
        if (ids) {
            idsStr = ids.join(",")
        }
        return this.makeUrl(`bills/@/export?ids=${idsStr}&token=${userManager.getAccessToken()}`)
    }

    public async billsDelete(data) {
        return await this.request("POST", `bills/delete`, data)
    }

    public async suggestionsGetAll(options: any = {}) {
        return await this.request("GET", "suggestions", this.makeGetAllOptions(options))
    }

    public async suggestionsCreate(data) {
        return await this.request("POST", `suggestions`, data)
    }

    public async suggestionsUpdate(data) {
        return await this.request("PUT", `suggestions/${data.id}`, data)
    }
    public async suggestionsApprove(data) {
        return await this.request("PUT", `suggestions/${data.id}/approve`, data)
    }
    public async suggestionsApproveSpending(data) {
        return await this.request("PUT", `suggestions/${data.id}/approve_spending_status`, data)
    }

    public async suggestionsDelete(data) {
        return await this.request("DELETE", `suggestions/${data.id}`)
    }

    public async suggestionsCodeGetAll(options: any = {}) {
        return await this.request("GET", "suggestions_codes", this.makeGetAllOptions(options))
    }

    public async suggestionsCodeCreate(data) {
        return await this.request("POST", `suggestions_codes`, data)
    }

    public async suggestionsCodeDelete(id) {
        return await this.request("DELETE", `suggestions_codes/${id}`)
    }

    public async policiesGetAll(options: any = {}) {
        return await this.request("GET", "policies", this.makeGetAllOptions(options))
    }

    public async policiesCreate(data) {
        return await this.request("POST", `policies`, data)
    }

    public async policiesDelete(data) {
        return await this.request("DELETE", `policies/${data.id}`)
    }
    public async policiesUpdate(data) {
        return await this.request("PUT", `policies/${data.id}`, data)
    }
    public async policiesApproveSpending(data) {
        return await this.request("PUT", `policies/${data.id}/approve_spending_status`, data)
    }

    public async costNameGetAll(options: any = {}) {
        return await this.request("GET", "cost_name", this.makeGetAllOptions(options))
    }

    public async costNameCreate(data) {
        return await this.request("POST", `cost_name`, data)
    }

    public async costNameDelete(id) {
        return await this.request("DELETE", `cost_name/${id}`)
    }
    public async standardsGetAll(options: any = {}) {
        return await this.request("GET", "standards", this.makeGetAllOptions(options))
    }

    public async standardsCreate(data) {
        return await this.request("POST", `standards`, data)
    }

    public async standardsDelete(id) {
        return await this.request("DELETE", `standards/${id}`)
    }
    public async outstandingMoneysCreate(data) {
        return await this.request("POST", `outstanding_money`, data)
    }
    public async outstandingMoneyGetAll(options: any = {}) {
        return await this.request("GET", "outstanding_money", this.makeGetAllOptions(options))
    }
    public async outstandingMoneyUpdate(data) {
        return await this.request("PUT", `outstanding_money/${data.id}`, data)
    }

    public async conversationGetAll(options: any = {}) {
        return await this.request("GET", "conversations", this.makeGetAllOptions(options))
    }

    public async conversationGet(id) {
        return await this.request("GET", `conversations/${id}`)
    }

    public async conversationSwitchRecord(data) {
        return await this.request("PUT", `conversations/${data.id}/switch`, data)
    }

    public async conversationCreate(data) {
        return await this.request("POST", `conversations`, data)
    }

    public async conversationUpdate(data, fields = undefined) {
        return await this.request("PUT", `conversations/${data.id}`, { data, fields })
    }

    public async conversationDelete(data) {
        return await this.request("DELETE", `conversations/${data.id}`)
    }

    public async conversationMessageGetAll(conversationId, options: any = {}) {
        return await this.request("GET", `conversations/${conversationId}/messages`, this.makeGetAllOptions(options))
    }

    public async conversationMessageCreate(conversationId, data) {
        return await this.request("POST", `conversations/${conversationId}/messages`, data)
    }

    public async conversationMessageUpdate(conversationId, data, fields = undefined) {
        return await this.request("PUT", `conversations/${conversationId}/messages/${data.id}`, { data, fields })
    }

    public async conversationMessageDelete(conversationId, data) {
        return await this.request("DELETE", `conversations/${conversationId}/messages/${data.id}`)
    }

    // ------------------------------------------------------

    private makeUrl(path) {
        return `${this.baseUrl}/api/${path}`
    }

    private async request(method: any, url: string, data: any = {}, tryDisabled = false, formDataEnabled = false) {
        url = this.makeUrl(url)
        const headers = {}
        let params = {}
        const accessToken = userManager.getAccessToken()
        if (accessToken) {
            headers["Authorization"] = `Bearer ${accessToken}`
        }
        const impersonateUserInfo = userManager.getImpersonateUserInfo()
        if (method === "GET") {
            if (impersonateUserInfo) {
                headers["X-Impersonate-User-Id"] = impersonateUserInfo.id
            }
            params = data
            data = {}
        } else if (formDataEnabled) {
            const formData = new FormData()
            for (const k in data) {
                const v = data[k]
                formData.append(k, v)
            }
            headers["Content-Type"] = "multipart/form-data"
            data = formData
        }
        const f = async () => {
            const response = await axios.request({
                method, url, data, headers, params,
            });
            return response && response.data
        }
        if (tryDisabled) {
            return await f()
        }
        return await this.try(async () => {
            return await f()
        })
    }

    private async try(f: any) {
        try {
            return await f()
        } catch (err) {
            this.errorHandler && this.errorHandler(err)
        }
    }

    private makeGetAllOptions(options) {
        if (!options) {
            return
        }
        if (options.options) {
            const newOptions: any = {}
            newOptions.limit = options.options.itemsPerPage
            newOptions.offset = (options.options.page - 1) * options.options.itemsPerPage
            newOptions.orders = {}
            if (options.options.sortBy && options.options.sortBy.length) {
                for (const i in options.options.sortBy) {
                    newOptions.orders[options.options.sortBy[i]] = options.options.sortDesc[i] ? "desc" : "asc"
                }
            }

            options.options = newOptions
        } else if (options.rawOptions) {
            options.options = options.rawOptions
            delete options.rawOptions
        }
        const query = {}
        for (const k in options) {
            query[k] = JSON.stringify(options[k])
        }
        return query
    }
}

export default new ApiClient()
