declare global {
    interface Window {
        vueConfigProvider: any;
    }
}

export default {
    get(value: string) {
        const vueConfigProvider = window.vueConfigProvider
        return vueConfigProvider[value]
    }
}
