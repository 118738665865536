import Vue from 'vue'
import Layout from './views/Layout.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from './lang/i18n';
import moment from 'moment'
import VueReadMore from 'vue-read-more';
import "./assets/css/style.scss"
import ExpandableImage from "./components/ExpandableImage.vue"
Vue.config.productionTip = false

Vue.filter("dateTime", (value, format = "DD/MM/YYYY - HH:mm:ss") => {
    if (!value) {
        return "N/A"
    }
    if (!isNaN(value)) {
        return moment(value * 1000).format(format)
    }
    return moment(value).format(format)
})

Vue.filter("number", (value) => {
    if (!value) {
        return "0"
    }
    return Math.round(value * 100) / 100
})

Vue.filter("money", (value, integer = true) => {
    if (!value) {
        return "0"
    }
    if (integer) {
        value = Math.round(value)
    }

    if (!value) {
        return ""
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})

Vue.use(VueReadMore);


Vue.component("expandable-image", ExpandableImage)

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(Layout)
}).$mount('#app')
