export default {
    "LOGOUT": "退出",
    "USERS": "用户",
    "PACKAGES_ALL": "全选",
    "PACKAGES_LOAD_PENDING": "等装车",
    "TRUCKS_LOADED": "已装车",
    "LOGIN": "登录",
    "LOGIN_NAME": "用户名",
    "PASSWORD": "密码",
    "REQUIRED": "必要的",
    "CREATE_USER": "注册账号",
    "NAME": "户名",
    "ROLES": "权",
    "CODES": "员工编号",
    "CREATED_AT": "创建日期",
    "UPDATED_AT": "更新日期",
    "ACTIONS": "行动",
    "EDIT": "修改",
    "DELETE": "删除",
    "CANCEL": "取消",
    "SAVE": "保存",
    "CONFIRM_DELETE": "你确定要删除以上的内容吗？",
    "OK": "是的",
    "NO_DATA": "不是",
    "ROLE_K": "仓库",
    "ROLE_XNK": "进出口部",
    "ROLE_KD": "业务部",
    "ROLE_T": "总",
    "ROLE_KT": "会计",
    "CREATE_PACKAGE": "输入信息",
    "PACKAGE_ID": "单号",
    "PACKAGE_BATCH_ID": "货号",
    "USER_NAME": "账号",
    "PACKAGE_COUNT": "件数",
    "WEIGHT": "重量",
    "VOLUME": "体积",
    "INTERIOR_COST": "运费",
    "PACKAGE_METHOD": "包装方式",
    "NOTE": "注意",
    "IMAGE": "图片",
    "TRUCK_ID": "车牌",
    "PENDING": "等装车",
    "STORE_TIME": "进仓日期",
    "TOTAL_WEIGHT": "总重量",
    "TOTAL_VOLUME": "总体积",
    "LOAD": "装车",
    "EXCEL_EXPORT": "简略excel档",
    "EXCEL_ADVANCED_EXPORT": "仔细excel 档",
    "TRUCK_DETAIL": "详细",
    "STATUS": "状态",
    "LICENSE_PLATE": "图片",
    "LOAD_TIME": "装车时间",
    "GATE_TIME": "到口岸时间 ",
    "VN_TIME": "到越南时间",
    "STATUS_DK": "预计",
    "STATUS_X": "已装车",
    "STATUS_CK": "口岸",
    "STATUS_VN": "到越南",
    "TRUCK": "车",
    "SUBMIT_LOAD": "成交",
    "ADD_PACKAGE_ID": "加运单",
    "CONFIRM_PACKAGE_CANCEL": "取消装车",
    "SEARCH": "搜索",
    "TRUCK_CREATED_TIME": "報刊截止日期",
    "NOTIFICATION": "通知",
}
