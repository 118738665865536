














import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      errorShown: false,
      error: "",
    };
  },
  created() {
    const self = this;
    apiClient.setErrorHandler((err) => {
      if (err.message && err.message.indexOf("status code 401") !== -1) {
        userManager.deleteAccessToken();
          self.router.push("/login");
          return;
      }
        self.errorShown = true;
        self.error = err;
    });
      apiClient.setRouter(this.$router);
  },
});
