import stateManager from '@/services/stateManager';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import cn from './cn';
import vn from './vn';

Vue.use(VueI18n);

const messages = {
  cn, vn,
};

const i18n = new VueI18n({
  locale: stateManager.get("language", "vn"),
  messages,
  fallbackLocale: 'vn'
});

export default i18n;
